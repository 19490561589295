<template>
    <div>
        <b-table :empty-filtered-text="$t('no_result')"
                 :empty-text="$t('no_result')"
                 bordered
                 responsive
                 :items="items"
                 :fields="fields"
                 show-empty
                 class="mb-4 table-dropdown no-scrollbar border rounded"
        >
            <template #cell(status)="row">
                <span class="badge badge-warning" v-if="row.item.status.includes('waiting')">
                    {{ getStatusText(row.item.status) }}
                </span>
                <span class="badge badge-success" v-else-if="row.item.status == 'uploaded'">
                    {{ getStatusText(row.item.status) }}
                </span>
            </template>
            <template v-if="is_download" #cell(download)="row">
                <b-button variant="primary" @click="downloadFile(row)">{{$t('download')}}</b-button>
            </template>
        </b-table>
    </div>
</template>

<script>

import DoubleMajorService from "@/services/DoubleMajorService";

export default {
    props: {
        documents: {
            type: Object
        },
        is_download:{
            default:false
        },
        double_major_id:null,
    },
    data() {
        return {
            items:[],
            keys:[],
            formLoading: false,
            status: [
                {status: "waiting", text: "Bekleniyor"},
                {status: "uploaded", text: "Yüklendi"}],
            fields: [
                {
                    key: ("name"),
                    label: this.$t('name'),
                    tdClass: 'text-center align-middle',
                    thClass: 'text-center',
                    sortable: false
                },
                {
                    key: 'status',
                    label: this.$t('status'),
                    tdClass: 'align-middle'
                },
                {
                    key: 'approved_by',
                    label: this.$t('approved_by'),
                    tdClass: 'text-center align-middle',
                    thClass: 'text-center',
                    sortable: false
                },
                {
                    key:'download',
                    label:"",
                    tdClass: !this.is_download ? 'd-none':'d-flex justify-content-center',
                    thClass: !this.is_download ? 'd-none':''
                }
            ],
        }
    },
    created() {
        this.items = Object.values(this.documents);
        this.keys = Object.keys(this.documents);

    },
    methods:{
        getStatusText(value){
            let position = this.status.find(x=>x.status==value);
            if(position == null) return
            let explanation = position.text
            return explanation;
        },
        downloadFile(row){
            let key =this.keys.find(key=>{
                return this.items.some(item=>{
                    return this.documents[key].name === item.name
                })
            })
            if(!key) return
            DoubleMajorService.download(this.double_major_id,key).then(res=>{
                this._downloadFile(res,row.item.name+'.pdf')
            }).catch(err=>this.showErrors(err))
        }

    }
}
</script>

import Api from "@/services/Index"

const save = () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.get("double/major/preferences/save")
}
const studentAffairs = (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.get("double/major/preferences/student-affairs",config)
}
const prepSchool = (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.get("double/major/preferences/prep-school",config)
}
const academicUnitOffice = (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.get("double/major/preferences/academic-unit-office",config)
}
const store = (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.post("/double/major/preferences", formData)
}
const studentAffairsApprove = (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.post("/double/major/preferences/student-affairs/approve", formData)
}
const prepSchoolApprove = (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.post("/double/major/preferences/prep-school/approve", formData)
}
const academicUnitOfficeApprove = (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.post("/double/major/preferences/academic-unit-office/approve", formData)
}
const del = (id)=>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.delete("/double/major/preferences/"+id)
}

export default {
    save,
    prepSchool,
    studentAffairs,
    academicUnitOffice,
    store,
    studentAffairsApprove,
    academicUnitOfficeApprove,
    prepSchoolApprove,
    del
}

import Api from '@/services/Index';

const setDefaultBaseURL=()=>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
}
const studentForm = ()=>{
    setDefaultBaseURL();
    return Api.get("/double/majors/student-form")
}
const get = (id)=>{
    setDefaultBaseURL()
    return Api.get("/double/majors/"+id)
}
const getAll = (config)=>{
    setDefaultBaseURL()
    return Api.get("/double/majors",config)
}

const download = (id,type)=>{
    setDefaultBaseURL()
    return Api.get("/double/major/download/"+id+'/'+type,{responseType:"arraybuffer"})
}
const store = ()=>{
    setDefaultBaseURL()
    return Api.post("double/majors");
}
const storeFile = (formData)=>{
    setDefaultBaseURL()
    return Api.post("/double/majors/upload",formData);
}
const del= (id)=>{
    setDefaultBaseURL()
    return Api.delete("/double/majors/"+id)
}
const downloadAsExcel = (config)=>{
    setDefaultBaseURL()
    return Api.get('/double/majors/excel-export',{...config,responseType:'arraybuffer'})
}
export default {
    studentForm,storeFile,getAll,get,store,del,download,downloadAsExcel
}
